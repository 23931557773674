/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Tab, Tabs } from "@mui/material";

import { useNoFetch } from "src/hooks/UtilityHooks";

import {
  FixedHeightScrollLastChild,
  TableCardContainer,
} from "@components/StyledComponents";
import { useAlert } from "@features/confirm/useAlert";
import { useMutateError } from "@features/errors";
import { Filters, useFilterParams } from "@features/filters";
import {
  ItemCatalogView,
  ItemViewButtons,
  usePaginatedItemsQuery,
} from "@features/items";
import {
  orderTypeLabel,
  useCreateOrderSetVariantsMutation,
  useDraftOrderSetQuery,
  useDraftOrderSetsQuery,
} from "@features/orders";
import AddToCartButton from "@features/orders/ordering/AddToCartButton";
import OrderSetPreviewTooltip from "@features/orders/ordering/OrderSetPreviewTooltip";
import VariantSelectionModal from "@features/orders/ordering/VariantSelectionModal";
import { useOnDemandNationalCouponPrograms } from "@features/programs";
import { RequestReportButton, useReport } from "@features/reports";
import { Button, FaIcon, PageTitle } from "@features/ui";
import { Item } from "@models/Item";
import { useSetLocation } from "@services/reactRouterDom";
import useRoleIs from "@utils/useRoleIs";

const PlaceOrder = ({
  orderType,
}: {
  orderType: "from-inventory" | "on-demand";
}) => {
  const { hash } = useLocation();
  const setLocation = useSetLocation();

  const orderingCoupons = orderType === "on-demand" && hash === "#coupons";

  const orderTypeName = orderTypeLabel(orderType);
  const setError = useMutateError();
  const alert = useAlert();
  const roleIs = useRoleIs();
  const [filters] = useFilterParams();
  const orderSetIdRef = useRef<string | null>(null);
  const { channel, territory } = useSelector((state: any) => state.app);
  const [isAddingAllToCart, setIsAddingAllToCart] = useState(false);
  const [variantSelectionItems, setVariantSelectionItems] = useState<
    null | Item[]
  >(null);

  const createOrderSetVariantsMutation = useCreateOrderSetVariantsMutation();
  const { isLoading: isLoadingOrderSets } = useDraftOrderSetsQuery();
  const { data: orderSet } = useDraftOrderSetQuery(orderType);
  orderSetIdRef.current = orderSet?.id ?? null;

  const osProgramId = orderSet?.program?.id;
  const programIdFromFilter =
    orderType === "on-demand" && filters.programs?.length === 1
      ? filters.programs[0]
      : null;

  const orderSetVariants = orderSet?.orderSetVariants ?? [];

  // Initialize a promise queue
  const mutationQueue = useRef<Promise<any>>(Promise.resolve());

  const addVariantsToOrderSet = async (variantIds: string[]) => {
    if (orderSet) {
      let programError = "";
      if (osProgramId && programIdFromFilter !== osProgramId) {
        programError =
          "This item is not part of the selected program and cannot be added to this order. Please finish checking out and create a separate order for this item.";
      }
      if (programError) {
        await alert(programError, {
          title: "Invalid Program",
          confirmButtonText: "Cancel Action",
        });
        return;
      }
    }

    return new Promise<void>((resolve) => {
      // chain the promise so that the mutation is only called once at a time
      mutationQueue.current = mutationQueue.current.then(() => {
        return (
          createOrderSetVariantsMutation
            .mutateAsync({
              // always use the latest order-set id
              orderSetId: orderSetIdRef.current,
              variantIds,
              orderType,
              programId: programIdFromFilter,
            })
            .catch((err) => setError(err, "Add Order Set Variants"))
            // resolve the parent promise when the mutation is done
            .finally(() => resolve())
        );
      });
    });
  };
  const addItemToOrderSet = async (item: Item) => {
    const defaultVariant = item.variants!.find(
      (v) => v.selectedVariantOptions.length === 0
    );

    if (defaultVariant?.isActive) {
      await addVariantsToOrderSet([defaultVariant.id]);
    } else {
      setVariantSelectionItems([item]);
    }
  };

  const couponProgramIds = useOnDemandNationalCouponPrograms().map((p) => p.id);
  const programIdsFilter = orderingCoupons
    ? filters.programs ?? couponProgramIds
    : filters.programs;

  const queryEnabled = !orderingCoupons || !!programIdsFilter?.length;

  const params = {
    filter: {
      isOrderable: true,
      ...(orderType === "from-inventory"
        ? { isInventory: true }
        : { isOrderableForOnDemand: true }),
      searchTerm: filters.q,
      favorites: filters.favorites,
      brandIds: filters.brands,
      itemTypeIds: filters.itemTypes,
      programIds: programIdsFilter,
      businessUnitNames: filters.businessUnits,
      programTerritoryIds: filters.territories ?? (territory && [territory]),
      isAccolade: filters.isAccolade && filters.isAccolade === "yes",
      channel,
    },
    sort: filters.sort,
  };
  useReport("inventory-order-items", ["items", params]);

  const { data, ...tableProps } = usePaginatedItemsQuery(params, {
    enabled: queryEnabled,
    placeholderData: (prevData) => (queryEnabled ? prevData : undefined),
  });

  const orderSetVariantIds =
    orderSet?.orderSetVariants.map((osv) => osv.variant.id) ?? [];

  const variantsNotInCart = data.flatMap((item) =>
    item.variants.filter(
      (v) => v.isActive && !orderSetVariantIds.includes(v.id)
    )
  );

  const addAllItemsToOrderSet = async () => {
    setIsAddingAllToCart(true);
    const allVariantAreDefault = variantsNotInCart.every(
      (v) => v.selectedVariantOptions.length === 0
    );
    if (allVariantAreDefault) {
      await addVariantsToOrderSet(
        variantsNotInCart.map((variant) => variant.id)
      );
    } else {
      const itemsNotCompletelyInCart = data.filter((item) =>
        item.variants.some((v) => variantsNotInCart.includes(v))
      );
      setVariantSelectionItems(itemsNotCompletelyInCart);
    }
    setIsAddingAllToCart(false);
  };

  useNoFetch();
  return (
    <FixedHeightScrollLastChild>
      {variantSelectionItems && (
        <VariantSelectionModal
          items={variantSelectionItems}
          orderType={orderType}
          orderSetVariants={orderSetVariants}
          createOrUpdateOrderSet={addVariantsToOrderSet}
          onClose={() => setVariantSelectionItems(null)}
        />
      )}
      <div tw="flex items-center justify-between">
        <div tw="flex items-center gap-3">
          <PageTitle title={`Place ${orderTypeName} Order`} />
          {orderType === "on-demand" && (
            <Tabs
              value={hash}
              onChange={(_e, v) => setLocation({ hash: v }, { replace: true })}
              tw="min-h-0"
            >
              <Tab
                label="Standard"
                value={""}
                tw="py-2 min-h-0 text-neutral-400 tracking-wider"
              />
              <Tab
                label="Coupons"
                value={"#coupons"}
                tw="py-2 min-h-0 text-neutral-400 tracking-wider"
              />
            </Tabs>
          )}
        </div>

        <div tw="flex gap-3">
          {filters.programs?.length === 1 && (
            <Button
              onClick={addAllItemsToOrderSet}
              disabled={
                isLoadingOrderSets ||
                tableProps.isLoading ||
                variantsNotInCart.length === 0
              }
              loading={createOrderSetVariantsMutation.isPending}
              endIcon={<FaIcon icon="plus" tw="text-base! mr-1" />}
            >
              Add all to cart
            </Button>
          )}
          {orderType === "from-inventory" && (
            <RequestReportButton reportName="inventory-order-items" />
          )}
          {!roleIs("read-only", "compliance") && (
            <OrderSetPreviewTooltip orderSet={orderSet}>
              <Button
                disabled={!orderSet}
                loading={isLoadingOrderSets}
                component={Link}
                to={`/orders/open/${orderSet?.id}`}
                variant="contained"
              >
                {orderSet ? `View Order` : "New Order"}
              </Button>
            </OrderSetPreviewTooltip>
          )}
        </div>
      </div>
      <TableCardContainer>
        <div tw="flex items-start justify-between gap-3">
          <Filters
            searchTitle="Search Items"
            alwaysShow={["brands", "programs"]}
            slots={[
              ...(orderingCoupons ? ["nationalCouponPrograms"] : []),
              "favorites",
              "brands",
              "itemTypes",
              ...(orderingCoupons ? [] : ["programs"]),
              "businessUnits",
              "isAccolade",
              "territories",
            ]}
            slotProps={{
              brands: {
                contentWhenEmpty: "Order by Brand",
              },
              programs: {
                contentWhenEmpty: "Order by Program",
              },
            }}
            defaultValues={{
              ...(osProgramId && { programs: [osProgramId] }),
              sort: "brand",
            }}
          />

          <ItemViewButtons />
        </div>

        <ItemCatalogView
          rows={data}
          {...tableProps}
          orderView={orderType}
          rowActions={(item) => (
            <AddToCartButton
              item={item}
              orderSetVariants={orderSetVariants}
              addToOrderSet={addItemToOrderSet}
              orderType={orderType}
              loading={isLoadingOrderSets || isAddingAllToCart}
            />
          )}
        />
      </TableCardContainer>
    </FixedHeightScrollLastChild>
  );
};

export default PlaceOrder;
